@charset "UTF-8";
.szh-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  width: max-content;
  z-index: 100;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.szh-menu:focus {
  outline: none;
}
.szh-menu__arrow {
  box-sizing: border-box;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-left-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.1);
  z-index: -1;
}
.szh-menu__arrow--dir-left {
  right: -0.375rem;
  transform: translateY(-50%) rotate(135deg);
}
.szh-menu__arrow--dir-right {
  left: -0.375rem;
  transform: translateY(-50%) rotate(-45deg);
}
.szh-menu__arrow--dir-top {
  bottom: -0.375rem;
  transform: translateX(-50%) rotate(-135deg);
}
.szh-menu__arrow--dir-bottom {
  top: -0.375rem;
  transform: translateX(-50%) rotate(45deg);
}
.szh-menu__item {
  cursor: pointer;
}
.szh-menu__item:focus {
  outline: none;
}
.szh-menu__item--hover {
  background-color: #ebebeb;
}
.szh-menu__item--focusable {
  cursor: default;
  background-color: inherit;
}
.szh-menu__item--disabled {
  cursor: default;
  color: #aaa;
}
.szh-menu__group {
  box-sizing: border-box;
}
.szh-menu__radio-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.szh-menu__divider {
  height: 1px;
  margin: 0.5rem 0;
  background-color: rgba(0, 0, 0, 0.12);
}

.szh-menu-button {
  box-sizing: border-box;
}

.szh-menu {
  user-select: none;
  color: #212529;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.133), 0 0.6px 2px rgba(0, 0, 0, 0.1);
  min-width: 10rem;
  padding: 0.5rem 0;
}
.szh-menu__item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.375rem 1.5rem;
}
.szh-menu-container--itemTransition .szh-menu__item {
  transition-property: background-color, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.szh-menu__item--type-radio {
  padding-left: 2.2rem;
}
.szh-menu__item--type-radio::before {
  content: "○";
  position: absolute;
  left: 0.8rem;
  top: 0.55rem;
  font-size: 0.8rem;
}
.szh-menu__item--type-radio.szh-menu__item--checked::before {
  content: "●";
}
.szh-menu__item--type-checkbox {
  padding-left: 2.2rem;
}
.szh-menu__item--type-checkbox::before {
  position: absolute;
  left: 0.8rem;
}
.szh-menu__item--type-checkbox.szh-menu__item--checked::before {
  content: "✔";
}
.szh-menu__submenu > .szh-menu__item {
  padding-right: 2.5rem;
}
.szh-menu__submenu > .szh-menu__item::after {
  content: "❯";
  position: absolute;
  right: 1rem;
}
.szh-menu__header {
  color: #888;
  font-size: 0.8rem;
  padding: 0.2rem 1.5rem;
  text-transform: uppercase;
}

@keyframes szh-menu-show-slide-left {
  from {
    opacity: 0;
    transform: translateX(0.75rem);
  }
}
@keyframes szh-menu-hide-slide-left {
  to {
    opacity: 0;
    transform: translateX(0.75rem);
  }
}
@keyframes szh-menu-show-slide-right {
  from {
    opacity: 0;
    transform: translateX(-0.75rem);
  }
}
@keyframes szh-menu-hide-slide-right {
  to {
    opacity: 0;
    transform: translateX(-0.75rem);
  }
}
@keyframes szh-menu-show-slide-top {
  from {
    opacity: 0;
    transform: translateY(0.75rem);
  }
}
@keyframes szh-menu-hide-slide-top {
  to {
    opacity: 0;
    transform: translateY(0.75rem);
  }
}
@keyframes szh-menu-show-slide-bottom {
  from {
    opacity: 0;
    transform: translateY(-0.75rem);
  }
}
@keyframes szh-menu-hide-slide-bottom {
  to {
    opacity: 0;
    transform: translateY(-0.75rem);
  }
}
.szh-menu--state-opening.szh-menu--dir-left {
  animation: szh-menu-show-slide-left 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-left {
  animation: szh-menu-hide-slide-left 0.15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-right {
  animation: szh-menu-show-slide-right 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-right {
  animation: szh-menu-hide-slide-right 0.15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-top {
  animation: szh-menu-show-slide-top 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-top {
  animation: szh-menu-hide-slide-top 0.15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-bottom {
  animation: szh-menu-show-slide-bottom 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-bottom {
  animation: szh-menu-hide-slide-bottom 0.15s ease-in forwards;
}

.szh-menu__header {
  font-size: 12px;
}

.szh-menu-button {
  border: none;
  position: relative;
  padding: 0px;
  background: transparent;
  cursor: pointer;
}

.notification {
  width: 8px;
  height: 8px;
  background: #ba000d;
  z-index: 1;
  position: absolute;
  right: -4px;
  top: -4px;
  border-radius: 8px;
}

.app-switcher .app {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
  padding: 8px 0;
  align-items: center;
}

.app-switcher .appName {
  display: flex;
}

.app-switcher .app-badge {
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  background-color: #008cba;
  border-radius: 10px;
}

.app-switcher .app-icon {
  margin-right: 8px;
  color: #626262;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.fa-spinner {
  animation: spinner 1s linear infinite;
}

.Modal {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 500px !important;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  font-size: 16px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.modal-button-panel {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 16px;
}

.bsq-btn {
  border-radius: 0.25rem;
  border: 1px solid #ddd;
  background-color: #f3f4f5;
  padding: 8px;
}

.org-switcher-button {
  padding: 4px;
  color: white;
  font-size: 14px;
  display: flex;
  gap: 8px;
}

.pos svg {
  color: #00000080;
  margin-right: 8px;
}

.login-footer {
  position: relative;
  background-color: hsla(0, 0%, 100%, 0.95);
  padding: 15px 0;
  text-align: center;
  box-shadow: 0 -2px 10px rgb(0 0 0 / 3%);
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.login-footer img {
  margin: 5px;
  height: 25px;
}

.copyright-text {
  color: #555;
  font-style: italic;
  float: right;
  margin: 17px 20px 0 -155px;
  font-size: 12px;
  top: 10px;
  right: 20px;
}

.login-footer img {
  margin: 5px;
  height: 25px;
}

.login-footer .comodo-logo {
  margin: 0px;
  height: 45px;
}

.login-footer .security-metrics-logo {
  margin-left: 10px;
  height: 30px;
}

@media (max-width: 991.98px) {
  .copyright-text {
    margin: 10px 0;
    width: 100%;
  }
}
